import { Permission } from '@/domain/permissions';
import NotAllowedPage from '@/pages/403';
import {
  BarChartOutlined,
  CheckCircleOutlined,
  CodeSandboxOutlined,
  ContactsOutlined,
  ControlOutlined,
  DollarOutlined,
  FileTextOutlined,
  HomeOutlined,
  MenuOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  TagOutlined,
  TeamOutlined,
  ToolOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { FC } from 'react';
import { lazy } from 'react';

const AuthCallbackPage = lazy(() => import('@/pages/auth/callback'));
const AttorneysPage = lazy(() => import('@/pages/contacts/attorneys'));
const SecurityLayout = lazy(() => import('@/layouts/SecurityLayout'));
const ContextProvidersLayout = lazy(() => import('@/layouts/ContextProvidersLayout'));
const IframePage = lazy(() => import('@/pages/dashboard/components/IframePage'));
const ContactPage = lazy(() => import('@/pages/contact'));
const CommunicationsPhoneCallsPage = lazy(() => import('@/pages/communications/PhoneCalls'));
const CommunicationsMessagesPage = lazy(() => import('@/pages/communications/messages'));
const CommunicationsEmailsPage = lazy(() => import('@/pages/communications/emails'));
const TicketsPage = lazy(() => import('@/pages/tickets'));
const MyTasksPage = lazy(() => import('@/pages/tasks/my-tasks'));
const MyProcessTasksPage = lazy(() => import('@/pages/tasks/my-process-tasks'));
const OnboardingTasksPage = lazy(() => import('@/pages/tasks/onboarding-tasks'));
const RenovationTasksPage = lazy(() => import('@/pages/tasks/renovation-tasks'));
const ListingTasksPage = lazy(() => import('@/pages/tasks/listing-tasks'));
const LeasingTasksPage = lazy(() => import('@/pages/tasks/leasing-tasks'));
const MoveInTasksPage = lazy(() => import('@/pages/tasks/move-in-tasks'));
const MoveOutTasksPage = lazy(() => import('@/pages/tasks/move-out-tasks'));
const TurnTasksPage = lazy(() => import('@/pages/tasks/turn-tasks'));
const DispositionTasksPage = lazy(() => import('@/pages/tasks/disposition-tasks'));
const EvictionsTasksPage = lazy(() => import('@/pages/tasks/evictions-tasks'));
const AcquisitionsPropertiesPage = lazy(() => import('@/pages/acquisitions/properties'));
const AcquisitionsProfilesPage = lazy(() => import('@/pages/acquisitions/profiles'));
const AcquisitionsProfilePage = lazy(() => import('@/pages/acquisitions/profile'));
const AcquisitionsPortfolioPage = lazy(() => import('@/pages/acquisitions/portfolios'));
const AcquisitionsPropertyDetailsPage = lazy(() => import('@/pages/acquisitions/property-details'));
const AcquisitionsPortfolioDetailsPage = lazy(
  () => import('@/pages/acquisitions/portfolio-details'),
);
const AcquisitionsRegionalAssumptionsPage = lazy(
  () => import('@/pages/acquisitions/regional-assumptions'),
);
// TODO this is importing the wrong page, it should be `src/pages/acquisitions/state-details.tsx`
const AcquisitionsStateDetailsPage = lazy(() => import('@/pages/acquisitions/property-details'));
const PropertiesPage = lazy(() => import('@/pages/property/view'));
const UnitRentalPricingPage = lazy(() => import('@/pages/property/unit-rental-pricing'));
const UnitsPage = lazy(() => import('@/pages/unit/view'));
const FloorPlansPage = lazy(() => import('@/pages/floor-plans/floor-plans'));
const FloorPlanDetailsPage = lazy(() => import('@/pages/floor-plans/FloorPlanDetailsPage'));
const UnitsDetailsPage = lazy(() => import('@/pages/unit/manage'));
const ListingsPage = lazy(() => import('@/pages/property/listings/ListingsPage'));
const ListingsDetailsPage = lazy(() => import('@/pages/property/listings/ListingDetails'));
const OnboardingPage = lazy(() => import('@/pages/property/onboardings/OnboardingsPage'));
const OnboardingDetailsPage = lazy(() => import('@/pages/property/onboardings/OnboardingDetails'));
const PropertyUtilitiesPage = lazy(() => import('@/pages/utilities/UtilitiesPage'));
const PropertyCommunitiesPage = lazy(() => import('@/pages/communities/index'));
const PropertyManageCommunityPage = lazy(() => import('@/pages/communities/CommunityDetails'));
const PropertyDetailsPage = lazy(() => import('@/pages/property/manage'));
const SystemsPage = lazy(() => import('@/pages/iot/view'));
const SystemDetailsPage = lazy(() => import('@/pages/iot/details'));
const SystemsUnassignedPropertiesPage = lazy(() => import('@/pages/iot/unassigned-properties'));
const SystemsVacantPropertiesPage = lazy(() => import('@/pages/iot/vacant-properties'));
const SystemsOccupiedPropertiesPage = lazy(() => import('@/pages/iot/occupied-properties'));
const PropertyGroups = lazy(() => import('@/pages/property/property-groups'));

const LeadsPage = lazy(() => import('@/pages/prospects/leads/index'));
const ShowingsPage = lazy(() => import('@/pages/prospects/showings'));
const MyShowingsPage = lazy(() => import('@/pages/prospects/showings/my-showings'));
const LeasingPage = lazy(() => import('@/pages/prospects/leasing'));
const LeasingDetailsPage = lazy(() => import('@/pages/prospects/leasing/details'));
const UnitApplicationsPage = lazy(
  () => import('@/pages/prospects/unit-applications/ApplicationsPage'),
);
const UnitApplicationDetailsPage = lazy(
  () => import('@/pages/prospects/unit-applications/details'),
);
const ApplicationsPage = lazy(() => import('@/pages/prospects/applications'));
const RenewalsPage = lazy(() => import('@/pages/prospects/renewals'));
const RenewalsDetailsPage = lazy(
  () => import('@/pages/prospects/renewals/components/RenewalsDetail'),
);
const TurnsPage = lazy(() => import('@/pages/turns/turns'));
const TurnWorkOrdersPage = lazy(() => import('@/pages/turns/turn-work-orders'));
const TurnJobsPage = lazy(() => import('@/pages/turns/turn-jobs'));
const RehabPage = lazy(() => import('@/pages/turns/rehabs'));
const RehabWorkOrdersPage = lazy(() => import('@/pages/turns/rehab-work-orders'));
const RehabJobsPage = lazy(() => import('@/pages/turns/rehab-jobs'));
const TurnsDetailsPage = lazy(() => import('@/pages/turns/details'));
const JobsPage = lazy(() => import('@/pages/maintenance/jobs'));
const JobWorkOrdersPage = lazy(() => import('@/pages/maintenance/jobs-work-orders'));
const JobsDetailsPage = lazy(() => import('@/pages/maintenance/job-details'));
const WorkOrdersPage = lazy(() => import('@/pages/maintenance/work-orders'));
const MyWorkOrdersPage = lazy(() => import('@/pages/maintenance/my-work-orders'));
const WorkOrdersDetailsPage = lazy(() => import('@/pages/maintenance/work-order-details'));
const RecurringWorkOrdersPage = lazy(() => import('@/pages/maintenance/recurring-work-orders'));
const RecurringServicePropertiesPage = lazy(
  () => import('@/pages/maintenance/recurring-service-properties'),
);
const BidsPage = lazy(() => import('@/pages/maintenance/bids'));
const VendorsPage = lazy(() => import('@/pages/maintenance/vendors'));
const VendorManagePage = lazy(() => import('@/pages/maintenance/manage'));
const RecurringServiceProvidersPage = lazy(
  () => import('@/pages/maintenance/recurring-service-providers'),
);
const RecurringWorkOrderTemplatesPage = lazy(
  () => import('@/pages/maintenance/recurring-work-order-templates'),
);
const RealEstateAgentPage = lazy(() => import('@/pages/contacts/real-estate-agent'));
const ResidentsPage = lazy(() => import('@/pages/resident/view'));
const ResidentsCollectionsPage = lazy(() => import('@/pages/resident/collections'));
const ResidentsMoveInsPage = lazy(() => import('@/pages/resident/moveins'));
const ResidentsMoveInDetailsPage = lazy(() => import('@/pages/resident/moveinDetails'));
const ResidentsMoveOutsPage = lazy(() => import('@/pages/resident/moveOuts'));
const ResidentsMoveOutDetailsPage = lazy(() => import('@/pages/resident/moveOutDetails'));
const ResidentsDisputesPage = lazy(() => import('@/pages/resident/disputes'));
const ResidentDisputeDetailsPage = lazy(() => import('@/pages/resident/disputes/details'));
const ResidentsEvictionsPage = lazy(() => import('@/pages/resident/evictions'));
const ResidentsEvictionsDetailsPage = lazy(() => import('@/pages/resident/evictions/details'));
const ReportsPage = lazy(() => import('@/pages/report-pages/index'));
const ReportDetailsPage = lazy(() => import('@/pages/report-pages/details'));
const UsersManagePage = lazy(() => import('@/pages/user/manage'));
const SurveysPage = lazy(() => import('@/pages/surveys'));
const SurveysDetailsPage = lazy(() => import('@/pages/surveys/surveyDetails'));
const SurveysResponseDetailsPage = lazy(() => import('@/pages/surveys/components/ResponseDetails'));
const SettingsPage = lazy(() => import('@/pages/settings'));
const SettingsUsersPage = lazy(() => import('@/pages/settings/users/view'));
const SettingsUserGroupsPage = lazy(() => import('@/pages/settings/user-groups/view'));
const SettingsUserGroupsDetailsPage = lazy(() => import('@/pages/settings/user-groups/details'));
const SettingsPermissionsPage = lazy(() => import('@/pages/settings/permissions'));
const SettingsCompaniesPage = lazy(() => import('@/pages/settings/companies'));
const SettingsIlsSyndicationPage = lazy(() => import('@/pages/settings/ils-syndication'));
const SettingsPropertyManagerPage = lazy(() => import('@/pages/settings/property-managers/view'));
const SettingsPropertyManagerDetailsPage = lazy(
  () => import('@/pages/settings/property-managers/manage'),
);
const SettingsPropertyManagerOfficePage = lazy(
  () => import('@/pages/settings/property-managers/office'),
);
const SettingsEmailsPage = lazy(() => import('@/pages/settings/email/view'));
const SettingsEmailsCreatePage = lazy(() => import('@/pages/settings/email/create'));
const SettingsEmailsDetailsPage = lazy(() => import('@/pages/settings/email/details'));
const FormViewPage = lazy(() => import('@/pages/form/view'));
const FormViewDetailsPage = lazy(() => import('@/pages/form/details'));
const PetPolicyPage = lazy(() => import('@/pages/pet-policy/view'));
const TemperatureProfilesPage = lazy(() => import('@/pages/settings/temperature-profiles/view'));
const MetadataPlaygroundPage = lazy(() => import('@/pages/metadata-playground/view'));
const PromotionsPage = lazy(() => import('@/pages/promotions/view'));
const PromotionsDetailsPage = lazy(() => import('@/pages/promotions/manage'));
const TicketGroupsPage = lazy(() => import('@/pages/settings/ticket-groups/view'));
const MaintenanceSettingsPage = lazy(() => import('@/pages/settings/maintenance-settings/view'));
const EmailGroupsPage = lazy(() => import('@/pages/settings/email-groups/view'));
const TicketCreationRulesPage = lazy(() => import('@/pages/settings/ticket-creation-rules'));
const SettingsRegionsPage = lazy(() => import('@/pages/settings/regions'));
const SettingsUserRolesPage = lazy(() => import('@/pages/settings/user-roles'));
const SettingsBrandsPage = lazy(() => import('@/pages/settings/brands/index'));
const InvoiceAccountsPage = lazy(() => import('@/pages/settings/invoice-accounts/index'));
const SettingsBrandDetailsPage = lazy(() => import('@/pages/settings/brands/details'));
const SettingsTaskTemplatesPage = lazy(() => import('@/pages/settings/task-templates/index'));
const SettingsPresetTasksPage = lazy(() => import('@/pages/settings/preset-tasks/index'));
const SettingsHoldingCompaniesPage = lazy(() => import('@/pages/settings/holding-companies/index'));
const SettingsApplicationFeesPage = lazy(() => import('@/pages/settings/application-fees'));
const UnitChargeTemplatesPage = lazy(
  () => import('@/pages/settings/unit-charges/UnitChargeTemplatesPage'),
);
const UnitChargeTemplatesDetailsPage = lazy(
  () => import('@/pages/settings/unit-charges/UnitChargeTemplatesDetailsPage'),
);
const PropertyGroupPointsOfContactPage = lazy(
  () => import('@/pages/settings/property-groups/PropertyGroupPointsOfContactPage'),
);
const FundsPage = lazy(() => import('@/pages/settings/funds'));
const UserMePage = lazy(() => import('@/pages/user/me'));
const DispositionsPage = lazy(() => import('@/pages/property/dispositions'));
const DispositionOfferRedirectPage = lazy(
  () => import('@/pages/property/dispositions/offers/redirect'),
);
const DispositionOfferDetailsPage = lazy(
  () => import('@/pages/property/dispositions/offers/details'),
);
const DispositionDetailsPage = lazy(
  () => import('@/pages/property/dispositions/DispositionDetailsPage'),
);
const PropertyGroupDetails = lazy(() => import('@/pages/property/property-group-details-page'));
const AutoFillDictionaryPage = lazy(
  () => import('@/pages/document-templates/AutoFillDictionaryPage'),
);
const DocumentTemplatesPage = lazy(
  () => import('@/pages/document-templates/DocumentTemplatesPage'),
);
const InvoicesPage = lazy(() => import('@/pages/invoices'));
const InvoiceDetailsPage = lazy(() => import('@/pages/invoices/InvoiceDetailsPage'));
const Welcome = lazy(() => import('@/pages/Welcome'));
const RoleDetailsPage = lazy(() => import('@/pages/settings/roles/details'));
const CallCenterPage = lazy(() => import('@/pages/call-center/CallCenterPage'));
const PublicAccountAgreementsPage = lazy(() => import('@/pages/public-account-agreements/view'));
const PublicAccountAgreementDetailsPage = lazy(
  () => import('@/pages/public-account-agreements/details'),
);

export type PropifyRoute = {
  path: string;
  component?: Function;
  routes?: PropifyRoute[];
  redirect?: string;
  permissions?: Permission[];
  // For more props see: https://umijs.org/en-US/docs/max/layout-menu
  props?: {
    name?: string;
    icon?: FC;
    hideInMenu?: boolean;
    path?: string;
    target?: '_blank';
    [key: string]: unknown;
  };
};

export const routes: PropifyRoute[] = [
  {
    path: '/auth',
    routes: [
      {
        path: '/auth/callback',
        component: AuthCallbackPage,
      },
    ],
  },
  {
    path: '/',
    component: SecurityLayout,
    routes: [
      {
        path: '/',
        component: ContextProvidersLayout,
        routes: [
          {
            path: '/',
            redirect: '/welcome',
          },
          {
            path: '/metadata-playground',
            component: MetadataPlaygroundPage,
            props: {
              name: 'Metadata Playground',
              hideInMenu: true,
            },
          },
          {
            path: '/dashboard',
            permissions: [Permission.READ_DASHBOARD],
            props: {
              name: 'Dashboard',
              icon: BarChartOutlined,
            },
            routes: [
              {
                path: '/dashboard',
                redirect: '/dashboard/leasing',
              },
              {
                path: '/dashboard/:name',
                component: IframePage,
                props: {
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/call-center',
            component: CallCenterPage,
            permissions: [Permission.READ_CALL_CENTER_DASHBOARD],
            props: {
              name: 'Call Center',
              icon: PhoneOutlined,
            },
          },
          {
            path: '/contacts',
            permissions: [Permission.READ_CONTACT],
            props: {
              name: 'Contacts',
              icon: UserOutlined,
            },
            routes: [
              {
                path: '/contacts/attorneys',
                permissions: [Permission.READ_ATTORNEY],
                component: AttorneysPage,
                props: {
                  name: 'Attorneys',
                },
              },
              {
                path: '/contacts/real-estate-agent',
                permissions: [Permission.READ_REAL_ESTATE_AGENT],
                component: RealEstateAgentPage,
                props: {
                  name: 'Real Estate Agents',
                },
              },
            ],
          },
          {
            path: '/contact',
            component: ContactPage,
            props: {
              name: 'Active Claims',
              icon: ContactsOutlined,
            },
          },
          {
            path: '/tickets',
            permissions: [Permission.READ_TICKET_MENU],
            props: {
              name: 'Tickets',
              icon: TagOutlined,
            },
            routes: [
              {
                path: '/tickets',
                redirect: '/tickets/all-tickets',
              },
              {
                path: '/tickets/:groupId',
                component: TicketsPage,
                props: {
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/tasks',
            permissions: [Permission.READ_TASK_MENU],
            props: {
              name: 'Tasks',
              icon: MenuOutlined,
            },
            routes: [
              {
                path: '/tasks/mine',
                permissions: [Permission.READ_MY_TASKS],
                component: MyTasksPage,
                props: {
                  name: 'My Tasks',
                },
              },
              {
                path: '/tasks/my-process',
                permissions: [Permission.READ_MY_PROCESS_TASKS],
                component: MyProcessTasksPage,
                props: {
                  name: 'My Process Tasks',
                },
              },
              {
                path: '/tasks/onboarding',
                permissions: [Permission.READ_ONBOARDING_TASKS],
                component: OnboardingTasksPage,
                props: {
                  name: 'Onboarding Tasks',
                },
              },
              {
                path: '/tasks/renovation',
                permissions: [Permission.READ_RENOVATION_TASKS],
                component: RenovationTasksPage,
                props: {
                  name: 'Renovation Tasks',
                },
              },
              {
                path: '/tasks/listing',
                permissions: [Permission.READ_LISTING_TASKS],
                component: ListingTasksPage,
                props: {
                  name: 'Listing Tasks',
                },
              },
              {
                path: '/tasks/leasing',
                permissions: [Permission.READ_LEASING_TASKS],
                component: LeasingTasksPage,
                props: {
                  name: 'Leasing Tasks',
                },
              },
              {
                path: '/tasks/move-in',
                permissions: [Permission.READ_MOVE_IN_TASKS],
                component: MoveInTasksPage,
                props: {
                  name: 'Move-In Tasks',
                },
              },
              {
                path: '/tasks/move-out',
                permissions: [Permission.READ_MOVE_OUT_TASKS],
                component: MoveOutTasksPage,
                props: {
                  name: 'Move-Out Tasks',
                },
              },
              {
                path: '/tasks/turn',
                permissions: [Permission.READ_TURN_TASKS],
                component: TurnTasksPage,
                props: {
                  name: 'Turn Tasks',
                },
              },
              {
                path: '/tasks/dispositions',
                permissions: [Permission.READ_DISPOSITIONS_TASKS],
                component: DispositionTasksPage,
                props: {
                  name: 'Disposition Tasks',
                },
              },
              {
                path: '/tasks/evictions',
                permissions: [Permission.READ_EVICTIONS_TASKS],
                component: EvictionsTasksPage,
                props: {
                  name: 'Evictions Tasks',
                },
              },
            ],
          },
          {
            path: '/prospects',
            permissions: [Permission.READ_PROSPECT_MENU],
            props: {
              name: 'Leasing',
              icon: UserAddOutlined,
            },
            routes: [
              {
                path: '/prospects',
                redirect: '/prospects/leasing',
                permissions: [Permission.READ_LEASING],
              },
              {
                path: '/prospects/leasing',
                component: LeasingPage,
                permissions: [Permission.READ_LEASING],
                props: {
                  name: 'Leasing Process',
                },
              },
              {
                path: '/prospects/leasing/:id',
                component: LeasingDetailsPage,
                permissions: [Permission.READ_LEASING],
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/prospects/unit-rental-pricing',
                component: UnitRentalPricingPage,
                permissions: [Permission.READ_UNIT_RENTAL_PRICING],
                props: {
                  name: 'Unit Rental Pricing',
                },
              },
              {
                path: '/prospects/leads',
                component: LeadsPage,
                permissions: [Permission.READ_LEAD],
                props: {
                  name: 'Leads',
                },
              },
              {
                path: '/prospects/showings',
                permissions: [Permission.READ_SHOWING],
                props: {
                  name: 'Showings',
                },
                component: ShowingsPage,
              },
              {
                path: '/prospects/my-showings',
                permissions: [Permission.READ_MY_SHOWINGS],
                props: {
                  name: 'My Showings',
                },
                component: MyShowingsPage,
              },
              {
                path: '/prospects/unit-applications',
                component: UnitApplicationsPage,
                permissions: [Permission.READ_APPLICATION],
                props: {
                  name: 'Unit Applications',
                },
              },
              {
                path: '/prospects/unit-applications/:id',
                component: UnitApplicationDetailsPage,
                permissions: [Permission.READ_APPLICATION],
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/prospects/applications',
                component: ApplicationsPage,
                permissions: [Permission.READ_PROSPECT_APPLICATION],
                props: {
                  name: 'Prospect Applications',
                },
              },
            ],
          },
          {
            path: '/document-templates',
            props: {
              name: 'Document Templates',
              icon: FileTextOutlined,
              // This is going to be available when document templates feature is complete
              hideInMenu: true,
            },
            routes: [
              {
                path: '/document-templates/view',
                component: DocumentTemplatesPage,
                props: {
                  name: 'Document Templates',
                },
              },
              {
                path: '/document-templates/autofill-dictionary',
                component: AutoFillDictionaryPage,
                props: {
                  name: 'Auto-Fill Dictionary',
                },
              },
            ],
          },
          {
            path: '/maintenance',
            permissions: [Permission.READ_FIELD_OPERATION_MENU],
            props: {
              name: 'Field Operations',
              icon: ToolOutlined,
            },
            routes: [
              {
                path: '/maintenance',
                redirect: '/maintenance/work-orders',
              },

              {
                path: '/maintenance/my-work-orders',
                permissions: [Permission.READ_MY_WORK_ORDERS],
                component: MyWorkOrdersPage,
                props: {
                  name: 'My Work Orders',
                },
              },
              {
                path: '/maintenance/work-orders',
                component: WorkOrdersPage,
                permissions: [Permission.READ_GENERAL_WORK_ORDER],
                props: {
                  name: 'Work Orders',
                  showWorkOrdersCount: false, // I'm skipping this in case we need to use them again
                },
              },
              {
                path: '/maintenance/bids',
                component: BidsPage,
                permissions: [Permission.READ_BID],
                props: {
                  name: 'Bids',
                },
              },
              {
                path: '/maintenance/vendors',
                component: VendorsPage,
                permissions: [Permission.READ_VENDOR_MENU],
                props: {
                  name: 'Vendors',
                },
              },
              {
                path: '/maintenance/turns',
                permissions: [Permission.READ_TURN_MENU],
                props: {
                  name: 'Turns',
                  showTurnRehabsCounts: false, // I'm skipping this in case we need to use them again
                },
                routes: [
                  {
                    path: '/maintenance/turns',
                    redirect: '/maintenance/turns/all',
                  },
                  {
                    path: '/maintenance/turns/all',
                    component: TurnsPage,
                    props: {
                      name: 'Turn Process',
                    },
                  },
                  {
                    path: '/maintenance/turns/turn-work-orders',
                    permissions: [Permission.READ_TURN_WORK_ORDERS],
                    component: TurnWorkOrdersPage,
                    props: {
                      name: 'Turn Work Orders',
                    },
                  },
                  {
                    path: '/maintenance/turns/turn-jobs',
                    permissions: [Permission.READ_TURN_JOBS],
                    component: TurnJobsPage,
                    props: {
                      name: 'Turn Jobs',
                    },
                  },
                ],
              },
              {
                path: '/maintenance/rehabs',
                permissions: [Permission.READ_REHAB_MENU],
                props: {
                  name: 'Renovations',
                  showTurnRehabsCounts: false, // I'm skipping this in case we need to use them again
                },
                routes: [
                  {
                    path: '/maintenance/rehabs',
                    redirect: '/maintenance/rehabs/all',
                  },
                  {
                    path: '/maintenance/rehabs/all',
                    component: RehabPage,
                    props: {
                      name: 'Renovations Process',
                    },
                  },
                  {
                    path: '/maintenance/rehabs/rehab-work-orders',
                    permissions: [Permission.READ_REHAB_WORK_ORDER],
                    component: RehabWorkOrdersPage,
                    props: {
                      name: 'Renovation Work Orders',
                    },
                  },
                  {
                    path: '/maintenance/rehabs/rehab-jobs',
                    permissions: [Permission.READ_REHAB_JOBS],
                    component: RehabJobsPage,
                    props: {
                      name: 'Renovation Jobs',
                    },
                  },
                ],
              },
              {
                path: '/maintenance/turn-rehabs/:id',
                component: TurnsDetailsPage,
                props: {
                  name: 'Turns/Rehabs',
                  hideInMenu: true,
                },
              },
              {
                path: '/maintenance/jobs',
                permissions: [Permission.READ_MAINTENANCE_JOB_MENU],
                props: {
                  name: 'Maint. Jobs',
                },
                routes: [
                  {
                    path: '/maintenance/jobs',
                    redirect: '/maintenance/jobs/all',
                  },
                  {
                    path: '/maintenance/jobs/all',
                    component: JobsPage,
                    props: {
                      // cspell:ignore Maint
                      name: 'Maint. Jobs',
                      showJobsCounts: false, // I'm skipping this in case we need to use them again
                    },
                  },
                  {
                    path: '/maintenance/jobs/job-work-orders',
                    permissions: [Permission.READ_MAINTENANCE_JOB_WORK_ORDERS],
                    component: JobWorkOrdersPage,
                    props: {
                      // cspell:ignore Maint
                      name: 'Maint. Job Work Orders',
                      showJobsCounts: false,
                    },
                  },
                ],
              },
              {
                path: '/maintenance/recurring-work-orders',
                permissions: [Permission.READ_RECURRING_FIELD_OPS_MENU],
                props: {
                  name: 'Recurring',
                },
                routes: [
                  {
                    path: '/maintenance/recurring-work-orders',
                    redirect: '/maintenance/recurring-work-orders/all',
                  },
                  {
                    path: '/maintenance/recurring-work-orders/all',
                    component: RecurringWorkOrdersPage,
                    props: {
                      name: 'Recurring Work Orders',
                    },
                  },
                  {
                    path: '/maintenance/recurring-work-orders/recurring-service-properties',
                    permissions: [Permission.READ_RECURRING_WORK_ORDERS_PROPERTIES],
                    component: RecurringServicePropertiesPage,
                    props: {
                      name: 'Recurring WO Properties',
                    },
                  },
                  {
                    path: '/maintenance/recurring-work-orders/recurring-service-providers',
                    permissions: [Permission.READ_RECURRING_SERVICE_PROVIDERS],
                    component: RecurringServiceProvidersPage,
                    props: {
                      name: 'Recurring WO Providers',
                    },
                  },
                  {
                    path: '/maintenance/recurring-work-orders/templates',
                    permissions: [Permission.READ_RECURRING_WORK_ORDER_TEMPLATES],
                    component: RecurringWorkOrderTemplatesPage,
                    props: {
                      name: 'Recurring WO Templates',
                    },
                  },
                ],
              },
              {
                path: '/maintenance/jobs/:id',
                component: JobsDetailsPage,
                permissions: [Permission.READ_JOB],
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/maintenance/work-orders/:id',
                component: WorkOrdersDetailsPage,
                permissions: [Permission.READ_WORK_ORDER],
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/maintenance/vendors/:id',
                component: VendorManagePage,
                permissions: [Permission.READ_VENDOR_MENU],
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/residents',
            permissions: [Permission.READ_RESIDENT_MENU],
            props: {
              name: 'Residents',
              icon: TeamOutlined,
            },
            routes: [
              {
                path: '/residents',
                redirect: '/residents/view',
              },
              {
                path: '/residents/view',
                component: ResidentsPage,
                permissions: [Permission.READ_RESIDENT],
                props: {
                  name: 'Residents',
                },
              },
              {
                path: '/residents/move-ins',
                component: ResidentsMoveInsPage,
                permissions: [Permission.READ_MOVE_IN],
                props: {
                  name: 'Move-Ins',
                },
              },
              {
                path: '/residents/move-ins/:id',
                component: ResidentsMoveInDetailsPage,
                permissions: [Permission.READ_MOVE_IN],
              },
              {
                path: '/residents/renewals',
                component: RenewalsPage,
                permissions: [Permission.READ_RENEWAL],
                props: {
                  name: 'Renewals',
                },
              },
              {
                path: '/residents/renewals/:id',
                component: RenewalsDetailsPage,
                permissions: [Permission.READ_RENEWAL],
                props: {
                  name: 'Renewal',
                  hideInMenu: true,
                },
              },
              {
                path: '/residents/move-outs',
                component: ResidentsMoveOutsPage,
                permissions: [Permission.READ_MOVE_OUT],
                props: {
                  name: 'Move-Outs',
                },
              },
              {
                path: '/residents/move-outs/:id',
                component: ResidentsMoveOutDetailsPage,
                permissions: [Permission.READ_MOVE_OUT],
              },
              {
                path: '/residents/collections',
                component: ResidentsCollectionsPage,
                permissions: [Permission.READ_COLLECTION],
                props: {
                  name: 'Past Due',
                },
              },
              {
                path: '/residents/evictions',
                component: ResidentsEvictionsPage,
                // permissions: [Permission.READ_EVICTIONS],
                props: {
                  name: 'Evictions',
                },
              },
              {
                path: '/residents/evictions/:id',
                component: ResidentsEvictionsDetailsPage,
                // permissions: [Permission.READ_EVICTIONS],
              },
              {
                path: '/residents/disputes',
                component: ResidentsDisputesPage,
                props: {
                  name: 'Disputes',
                  hideInMenu: true,
                },
              },
              {
                path: '/residents/disputes/:id',
                component: ResidentDisputeDetailsPage,
                props: {
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/acquisitions',
            props: {
              name: 'Acquisitions',
              icon: CodeSandboxOutlined,
              hideInMenu: true,
            },
            routes: [
              {
                path: '/acquisitions',
                redirect: '/acquisitions/properties',
              },
              {
                path: '/acquisitions/properties',
                component: AcquisitionsPropertiesPage,
                props: {
                  name: 'Properties',
                },
              },
              {
                path: '/acquisitions/portfolios',
                component: AcquisitionsPortfolioPage,
                props: {
                  name: 'Portfolios',
                },
              },
              {
                path: '/acquisitions/profiles',
                component: AcquisitionsProfilesPage,
                props: {
                  name: 'Underwriting Profiles',
                },
              },
              {
                path: '/acquisitions/properties/:id',
                component: AcquisitionsPropertyDetailsPage,
                props: {
                  name: 'Property',
                  hideInMenu: true,
                },
              },
              {
                path: '/acquisitions/portfolios/:id',
                component: AcquisitionsPortfolioDetailsPage,
                props: {
                  name: 'Portfolio Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/acquisitions/portfolios/:id/properties/:propertyId',
                component: AcquisitionsPropertyDetailsPage,
                props: {
                  name: 'Portfolio Property',
                  hideInMenu: true,
                },
              },
              {
                path: '/acquisitions/profiles/:id',
                component: AcquisitionsProfilePage,
                props: {
                  name: 'Profile',
                  hideInMenu: true,
                },
              },
              {
                path: '/acquisitions/regional-assumptions',
                component: AcquisitionsRegionalAssumptionsPage,
                props: {
                  name: 'Regional Assumptions',
                },
              },
              {
                path: '/acquisitions/regional-assumptions/:state',
                component: AcquisitionsStateDetailsPage,
                props: {
                  name: 'State Details',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/properties',
            permissions: [Permission.READ_PROPERTY_MENU],
            props: {
              name: 'Properties',
              icon: HomeOutlined,
            },
            routes: [
              {
                path: '/properties',
                redirect: '/properties/view',
                permissions: [Permission.READ_PROPERTY],
              },
              {
                path: '/properties/view',
                component: PropertiesPage,
                permissions: [Permission.READ_PROPERTY],
                props: {
                  name: 'Properties',
                },
              },
              {
                path: '/properties/property-groups',
                component: PropertyGroups,
                permissions: [Permission.READ_PROPERTY_GROUP],
                props: {
                  name: 'Property Groups',
                },
              },
              {
                path: '/properties/property-groups/:id',
                component: PropertyGroupDetails,
                permissions: [Permission.READ_PROPERTY_GROUP],
                props: {
                  name: 'Property Group Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/units',
                permissions: [Permission.READ_UNITS_PAGE],
                component: UnitsPage,
                props: {
                  name: 'Units',
                },
              },
              {
                path: '/properties/floor-plans',
                permissions: [Permission.READ_FLOOR_PLANS_PAGE],
                component: FloorPlansPage,
                props: {
                  name: 'Floor Plans',
                },
              },
              {
                path: '/properties/floor-plans/:id',
                component: FloorPlanDetailsPage,
                props: {
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/listings',
                component: ListingsPage,
                permissions: [Permission.READ_LISTING_PROCESS],
                props: {
                  name: 'Listings',
                },
              },
              {
                path: '/properties/listings/:id',
                component: ListingsDetailsPage,
                permissions: [Permission.READ_LISTING_PROCESS],
                props: {
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/onboardings',
                component: OnboardingPage,
                permissions: [Permission.READ_ONBOARDING_PROCESS],
                props: {
                  name: 'Onboardings',
                },
              },
              {
                path: '/properties/onboardings/:id',
                component: OnboardingDetailsPage,
                permissions: [Permission.READ_ONBOARDING_PROCESS],
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/dispositions',
                component: DispositionsPage,
                permissions: [Permission.READ_DISPOSITION],
                props: {
                  name: 'Dispositions',
                },
              },
              {
                path: '/properties/dispositions/offers/:id',
                component: DispositionOfferRedirectPage,
                permissions: [Permission.READ_DISPOSITION],
                props: {
                  name: 'Offer Redirect',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/dispositions/:dispositionId/offers/:id',
                component: DispositionOfferDetailsPage,
                permissions: [Permission.READ_DISPOSITION],
                props: {
                  name: 'Offer',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/dispositions/:id',
                component: DispositionDetailsPage,
                permissions: [Permission.READ_DISPOSITION],
                props: {
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/utilities',
                component: PropertyUtilitiesPage,
                permissions: [Permission.READ_UTILITY],
                props: {
                  name: 'Utilities',
                },
              },
              {
                path: '/properties/communities',
                component: PropertyCommunitiesPage,
                permissions: [Permission.READ_COMMUNITY_MENU, Permission.READ_COMMUNITIES_PAGE],
                props: {
                  name: 'Communities',
                },
              },
              {
                path: '/properties/communities/:id',
                component: PropertyManageCommunityPage,
                props: {
                  name: 'Community',
                  hideInMenu: true,
                },
              },
              {
                path: '/properties/:id',
                component: PropertyDetailsPage,
                permissions: [Permission.READ_PROPERTY],
                props: {
                  name: 'Manage',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/systems',
            permissions: [Permission.READ_SMART_HOME_MENU],
            props: {
              name: 'Smart Home',
              icon: ControlOutlined,
            },
            routes: [
              {
                path: '/systems',
                redirect: '/systems/view',
                permissions: [Permission.READ_SYSTEM],
              },
              {
                path: '/systems/view',
                component: SystemsPage,
                permissions: [Permission.READ_SYSTEM],
                props: {
                  name: 'Systems',
                },
              },
              {
                path: '/systems/:id',
                component: SystemDetailsPage,
                permissions: [Permission.READ_SYSTEM],
                props: {
                  name: 'System Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/systems/system-installation',
                component: SystemsUnassignedPropertiesPage,
                permissions: [Permission.READ_SYSTEM_INSTALLATION],
                props: {
                  name: 'System Installation',
                },
              },
              {
                path: '/systems/vacant-properties',
                component: SystemsVacantPropertiesPage,
                permissions: [Permission.READ_SYSTEM_VACANT_UNIT],
                props: {
                  name: 'Vacant Properties',
                },
              },
              {
                path: '/systems/occupied-properties',
                component: SystemsOccupiedPropertiesPage,
                permissions: [Permission.READ_SYSTEM_OCCUPIED_UNIT],
                props: {
                  name: 'Occupied Properties',
                },
              },
            ],
          },
          {
            path: '/invoices',
            component: InvoicesPage,
            props: {
              name: 'Invoice Approvals',
              icon: DollarOutlined,
            },
            permissions: [Permission.READ_INVOICE],
          },
          {
            path: '/invoices/:id',
            component: InvoiceDetailsPage,
            props: {
              hideInMenu: true,
            },
            permissions: [Permission.READ_INVOICE],
          },
          {
            path: '/units/:id',
            component: UnitsDetailsPage,
            props: {
              name: 'Unit',
              hideInMenu: true,
            },
          },
          {
            path: '/users',
            props: {
              name: 'Users',
              icon: TeamOutlined,
              hideInMenu: true,
            },
            routes: [
              {
                path: '/users/view',
                component: SettingsUsersPage,
                props: {
                  name: 'View',
                },
              },
              {
                path: '/users/:id',
                component: UsersManagePage,
                permissions: [Permission.UPDATE_USER],
                props: {
                  name: 'Manage',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/communications',
            permissions: [Permission.READ_COMMUNICATION_MENU],
            props: {
              name: 'Communications',
              icon: PhoneOutlined,
            },
            routes: [
              {
                path: '/communications',
                redirect: '/communications/messages',
              },
              {
                path: '/communications/phone-calls',
                component: CommunicationsPhoneCallsPage,
                props: {
                  name: 'Calls',
                },
              },
              {
                path: '/communications/messages',
                component: CommunicationsMessagesPage,
                props: {
                  name: 'Messages',
                },
              },
              {
                path: '/communications/emails',
                component: CommunicationsEmailsPage,
                props: {
                  name: 'Emails',
                },
              },
            ],
          },
          {
            path: '/report-pages',
            permissions: [Permission.READ_REPORTS_PAGE],
            props: {
              name: 'Reports',
              icon: CheckCircleOutlined,
            },
            routes: [
              {
                path: '/report-pages',
                component: ReportsPage,
                props: {
                  name: 'Reports',
                  hideInMenu: true,
                },
              },
              {
                path: '/report-pages/:id',
                component: ReportDetailsPage,
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/surveys',
            permissions: [Permission.READ_SURVEY],
            props: {
              name: 'Surveys',
              icon: CheckCircleOutlined,
            },
            routes: [
              {
                path: '/surveys',
                component: SurveysPage,
                props: {
                  name: 'Surveys',
                  hideInMenu: true,
                },
              },
              {
                path: '/surveys/:id',
                component: SurveysDetailsPage,
                props: {
                  name: 'Survey',
                  hideInMenu: true,
                },
              },
              {
                path: '/surveys/:surveyId/answers/:answerId',
                component: SurveysResponseDetailsPage,
                props: {
                  name: 'Survey Response',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/support',
            props: {
              name: 'Knowledge Center',
              icon: QuestionCircleOutlined,
              path: 'https://propifyfaq.freshdesk.com/support/solutions',
              target: '_blank',
            },
            routes: [
              {
                path: '/support',
                redirect: '/',
              },
            ],
          },
          {
            path: '/settings',
            permissions: [Permission.READ_SETTING_MENU],
            props: {
              name: 'Settings',
              icon: SettingOutlined,
            },
            routes: [
              {
                path: '/settings',
                component: SettingsPage,
                props: {
                  name: 'Settings',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/users',
                component: SettingsUsersPage,
                props: {
                  name: 'Users',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/user-roles',
                component: SettingsUserRolesPage,
                props: {
                  name: 'User Roles',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/user-groups',
                component: SettingsUserGroupsPage,
                props: {
                  name: 'User Groups',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/user-groups/:id',
                component: SettingsUserGroupsDetailsPage,
                permissions: [Permission.READ_USER],
                props: {
                  name: 'User Group Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/permissions',
                component: SettingsPermissionsPage,
                props: {
                  name: 'Permissions',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/companies',
                component: SettingsCompaniesPage,
                props: {
                  name: 'Companies',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/ils-syndication',
                component: SettingsIlsSyndicationPage,
                props: {
                  name: 'ILS Syndication',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/property-managers',
                component: SettingsPropertyManagerPage,
                props: {
                  name: 'Property Managers',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/property-managers/:id',
                component: SettingsPropertyManagerDetailsPage,
                props: {
                  name: 'Property Manager Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/property-managers/:companyId/office/:id',
                component: SettingsPropertyManagerOfficePage,
                props: {
                  name: 'Office Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/email-templates',
                component: SettingsEmailsPage,
                permissions: [Permission.READ_EMAIL_TEMPLATE],
                props: {
                  name: 'Email Templates',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/email-templates/create',
                component: SettingsEmailsCreatePage,
                permissions: [Permission.CREATE_EMAIL_TEMPLATE],
                props: {
                  name: 'Create Email Template',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/email-templates/:id',
                component: SettingsEmailsDetailsPage,
                permissions: [Permission.UPDATE_EMAIL_TEMPLATE],
                props: {
                  name: 'Email Template',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/user-roles/:id',
                component: RoleDetailsPage,
                props: {
                  name: 'Role Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/forms',
                component: FormViewPage,
                props: {
                  name: 'Forms',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/forms/:id',
                component: FormViewDetailsPage,
                props: {
                  name: 'Form Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/pet-policy',
                component: PetPolicyPage,
                props: {
                  name: 'Pet Policy',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/temperature-profiles',
                component: TemperatureProfilesPage,
                props: {
                  name: 'Temperature Profiles',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/promotions',
                component: PromotionsPage,
                props: {
                  name: 'Promotions',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/promotions/:id',
                component: PromotionsDetailsPage,
                props: {
                  name: 'Promotions Manage',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/ticket-groups',
                component: TicketGroupsPage,
                props: {
                  name: 'Ticket Groups',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/maintenance-settings',
                component: MaintenanceSettingsPage,
                props: {
                  name: 'Maintenance Settings',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/email-groups',
                component: EmailGroupsPage,
                props: {
                  name: 'Email Groups',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/ticket-creation-rules',
                component: TicketCreationRulesPage,
                props: {
                  name: 'Ticket Creation Rules',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/regions',
                component: SettingsRegionsPage,
                props: {
                  name: 'Regions',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/brands',
                component: SettingsBrandsPage,
                props: {
                  name: 'Brands',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/invoice-accounts',
                component: InvoiceAccountsPage,
                props: {
                  name: 'Invoice Accounts',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/brands/:id',
                component: SettingsBrandDetailsPage,
                props: {
                  name: 'Brand Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/task-templates',
                component: SettingsTaskTemplatesPage,
                props: {
                  name: 'Task Templates',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/preset-tasks',
                component: SettingsPresetTasksPage,
                props: {
                  name: 'Preset Tasks',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/holding-companies',
                component: SettingsHoldingCompaniesPage,
                props: {
                  name: 'Holding Companies',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/application-fees',
                component: SettingsApplicationFeesPage,
                props: {
                  name: 'Application Fees',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/public-account-agreements',
                component: PublicAccountAgreementsPage,
                permissions: [Permission.READ_PUBLIC_ACCOUNT_AGREEMENT],
                props: {
                  name: 'Public Account Agreements',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/public-account-agreements/:id',
                component: PublicAccountAgreementDetailsPage,
                permissions: [Permission.READ_PUBLIC_ACCOUNT_AGREEMENT],
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/unit-charge-templates',
                component: UnitChargeTemplatesPage,
                permissions: [Permission.READ_CHARGE_TEMPLATE],
                props: {
                  name: 'Unit Charge Templates',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/unit-charge-templates/:id',
                component: UnitChargeTemplatesDetailsPage,
                props: {
                  name: 'Details',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/property-group-points-of-contact',
                component: PropertyGroupPointsOfContactPage,
                props: {
                  name: 'Property Group Points of Contact',
                  hideInMenu: true,
                },
              },
              {
                path: '/settings/funds',
                component: FundsPage,
                props: {
                  name: 'Funds',
                  hideInMenu: true,
                },
              },
            ],
          },
          {
            path: '/me',
            component: UserMePage,
            props: {
              name: 'Me',
              hideInMenu: true,
            },
          },
          {
            path: '/welcome',
            component: Welcome,
            props: {
              name: 'Welcome',
              hideInMenu: true,
            },
          },
        ],
      },
    ],
  },
];

export const unauthorizedRoute: Omit<PropifyRoute, 'path'> = {
  component: NotAllowedPage,
  props: {
    hideInMenu: true,
  },
};
